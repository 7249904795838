import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hmac-authentication",
      "style": {
        "position": "relative"
      }
    }}>{`HMAC Authentication`}<a parentName="h1" {...{
        "href": "#hmac-authentication",
        "aria-label": "hmac authentication permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>
    <p>{`The TaleFin API uses HMAC authentication. This means that every request sent by a Vendor must contain the necessary HMAC headers to be authenticated. This document explains how TaleFin implemented HMAC authentication.`}</p>
    <h2 {...{
      "id": "the-relation-between-api-tokens-hmac-and-vendors-on-talefin",
      "style": {
        "position": "relative"
      }
    }}>{`The Relation Between API Tokens, HMAC and Vendors on TaleFin`}<a parentName="h2" {...{
        "href": "#the-relation-between-api-tokens-hmac-and-vendors-on-talefin",
        "aria-label": "the relation between api tokens hmac and vendors on talefin permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Vendors need an API Token to use the TaleFin API. These Tokens can be either provided to a Vendor by TaleFin or created by a Vendor via the TaleFin Dashboard.
An API Token consists on a name, identifier and a secret, and must be used by the Vendors to compute their HMAC hash for each request.`}</p>
    <h2 {...{
      "id": "generating-tokens-via-the-dashboard-for-vendors",
      "style": {
        "position": "relative"
      }
    }}>{`Generating Tokens Via The Dashboard (For Vendors)`}<a parentName="h2" {...{
        "href": "#generating-tokens-via-the-dashboard-for-vendors",
        "aria-label": "generating tokens via the dashboard for vendors permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Vendors logged to the Dashboard can generate their own Tokens by clicking on API Token on the left side bar. The screen will show a list of Tokens (if any) and a `}<inlineCode parentName="p">{`+`}</inlineCode>{` button. The Vendor must then click on the `}<inlineCode parentName="p">{`+`}</inlineCode>{` button, type a name and click on `}<inlineCode parentName="p">{`Create`}</inlineCode>{`.
The Dashboard will show the identifier and the secret for the new Token. The Vendor must copy the secret because searching for it afterwards is not possible.`}</p>
    <h2 {...{
      "id": "using-the-tokens-to-build-an-hmac-request",
      "style": {
        "position": "relative"
      }
    }}>{`Using the Tokens to Build an HMAC Request`}<a parentName="h2" {...{
        "href": "#using-the-tokens-to-build-an-hmac-request",
        "aria-label": "using the tokens to build an hmac request permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`All TaleFin API requests require the following headers for HMAC authentication:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`header`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`"Date"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A timestamp`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`"Content-MD5"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A hash of the body of the request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`"Content-Type"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A Mime Type. Usually "application/json"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`"Authorization"`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`"HMAC <token_identifier>:<hmac_signature>"`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`These headers will be verified in the backend once TaleFin receives the request.`}</p>
    <p>{`The way a request is built vary depending on the language.`}</p>
    <h2 {...{
      "id": "example-usage",
      "style": {
        "position": "relative"
      }
    }}>{`Example Usage`}<a parentName="h2" {...{
        "href": "#example-usage",
        "aria-label": "example usage permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The following are examples on how to collect data from the API endpoints using different languages. The token information
(name, secret and identifier) gets provided when you create a webhook through the API. Alternatively,
contact us on `}<a parentName="p" {...{
        "href": "mailto:helpdesk@talefin.com"
      }}>{`helpdesk@talefin.com`}</a>{` and we can create them for you and provide integration assistance.
This information is then called when you receive the event `}<em parentName="p">{`application.completed`}</em>{`.`}</p>
    <h2 {...{
      "id": "python",
      "style": {
        "position": "relative"
      }
    }}>{`Python`}<a parentName="h2" {...{
        "href": "#python",
        "aria-label": "python permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`def get_bundle(self, application_id):
    token = {
    "name": "token-name",
    "secret": "token-secret",
    "identifier": "token-identifier"
    }

    method      = 'GET'
    body        = ''
    root_url    = 'https://banks.talefin.com'
    path        = '/api/applications/{}/bundle'.format(application_id)
    timestamp   = datetime.datetime.utcnow().strftime(
        "%a, %d %b %Y %H:%M:%S GMT")
    contentType = 'application/json'

    hash = hashlib.md5(body.encode())
    contentMD5 = b64encode(hash.digest()).decode('utf-8')
    message_parts = [method, contentMD5, contentType, timestamp, path]
    message = '\\n'.join(message_parts)

    signature = hmac.new(bytes(token['secret'], 'latin-1'),
                bytes(message, 'latin-1'), digestmod=hashlib.sha256)
    hmac_base64 = b64encode(signature.digest()).decode('utf-8')

    headers = {
        'Date': timestamp,
        'Content-MD5': contentMD5,
        'Content-Type': contentType,
        'Authorization': 'HMAC {}:{}'.format(token['identifier'], hmac_base64)
    }

    request = requests.Request(
        'GET', '{}{}'.format(root_url, path),
        data=body, headers=headers)
    prepped = request.prepare()
    prepped.headers = headers

    with requests.Session() as session:
        response = session.send(prepped)

    if response.status_code != 200:
        print("Bad status code: {}".format(response.status_code))
        print("Bad status: {}".format(response.text))
        print(root_url, path)
        raise()

    print('Retrieved bundle')
    bundle = response.json()
    return bundle`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "javascript",
      "style": {
        "position": "relative"
      }
    }}>{`Javascript`}<a parentName="h2" {...{
        "href": "#javascript",
        "aria-label": "javascript permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`async function getFromAPI(path) {
  const token = {
    name: 'xxxx',
    secret: 'xxxx',
    identifier: 'xxxx',
  };

  const method = 'GET';
  const body = '';

  const root = 'https://banks.talefin.com';
  const timestamp = new Date().toUTCString();
  const contentType = 'application/json';

  const hash = crypto.createHash('md5');
  hash.update(body);
  const contentMD5 = hash.digest('base64');

  const messageParts = [method, contentMD5, contentType, timestamp, path];
  const message = messageParts.join('\\n');

  const hmac = crypto.createHmac('sha256', token.secret);
  hmac.update(message);
  const hmacBase64 = hmac.digest('base64');

  const headers = {
    Date: timestamp,
    'Content-MD5': contentMD5,
    'Content-Type': contentType,
    Authorization: \`HMAC \${token.identifier}:\${hmacBase64}\`,
  };

  const response = await fetch(root + path, {
    method,
    headers,
    body: body == '' ? null : body,
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "php",
      "style": {
        "position": "relative"
      }
    }}>{`PHP`}<a parentName="h2" {...{
        "href": "#php",
        "aria-label": "php permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public function rawApiCall($identifier, $secret, $method, $path, $body = '')
{
    $now = now();
    $token = [
        'secret' => $secret,
        'identifier' => $identifier,
    ];
    $root = 'https://banks.talefin.com';

    $timestamp = $now->format('D, d M Y H:i:s')
    $contentType = 'application/json';
    $hash = md5($body, true);
    $contentMD5 = base64_encode($hash);
    $messageParts = [
        $method,
        $contentMD5,
        $contentType,
        $timestamp,
        $path,
    ];
    $message = implode("\\n", $messageParts);
    $hash = hash_hmac('sha256', $message, $token['secret'], true);
    $hmacBase64 = base64_encode($hash);
    $headers = [
        'Date' => $timestamp,
        'Content-MD5' => $contentMD5,
        'Content-Type' => $contentType,
        'Authorization' => 'HMAC '.$token['identifier'].':'.$hmacBase64,
    ];
    $response = $this->client->request($method, $root.$path, [
        'verify' => false,
        'body' => $body ? $body : null,
        'headers' => $headers,
        'timeout' => 15
    ])->getBody()->getContents();
    $bytes = strlen($body);
    $secondsToRun = $now->diffInSeconds(now());
    return $response;
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "scala",
      "style": {
        "position": "relative"
      }
    }}>{`Scala`}<a parentName="h2" {...{
        "href": "#scala",
        "aria-label": "scala permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import java.security.MessageDigest
import java.util.Base64
import javax.crypto.Mac
import javax.crypto.spec.SecretKeySpec
import java.nio.charset.StandardCharsets

object CredfinHMAC {
    def base64(s: Array[Byte]): String = {
        Base64.getEncoder.encodeToString(s)
    }

    def md5(s: String): Array[Byte] = {
        MessageDigest.getInstance("MD5").digest(s.getBytes)
    }

    def hmac(sharedSecret: String, preHashString: String): Array[Byte] = {
        vol secret = new SecretKeySpec(sharedSecret.getBytes, "SHA256")
        val mac = Mac.getInstance("HmacSHA256")
        mac.init(secret)
        mac.doFinal(preHashString.getBytes)
    }

    def generateHeaders(identifier: String, secret: String, timestamp: String, method: String, contentType: String, path: String, body: String) = {
        var hash: Array[Byte] = md5(body)
        var contentMd5: String = base64(hash)
        var messageParts = List(method, contentMd5, contentType, timestamp, path).mkString("\\n")
        var hmacMessage: Array[Byte] = hmac(secret, messageParts)
        var hmacBase64: String = base64(hmacMessage)
        var formattedAuth = "HMAC " + identifier + ":" + hmacBase64

        Map("Date"->timestamp, "Content-MD5"->contentMd5, "Content-Type"->contentType, "Authorization"->formattedAuth)
    }

    def main(args: Array[String]) {
        /**** ENTER YOUR IDENTIFIER AND SECRET HERE ****/
        var identifier : String = "50m3cr3df1n1d3n71f13r"
        var secret : String = "50m3cr3d175up3r53cr37k3y"
        /**** PLEASE INSERT A TIMESTAMP HERE ****/
        var timestamp : String = "Fri, 04 Nov 2022 07:33:44 GMT"
        /**** SET THE HTTP METHOD BEING USED ****/
        var method: String = "POST"
        /**** SET THE HTTP CONTENT TYPE BEING USED ****/
        var contentType : String = "application/json"
        /**** SET THE HTTP ENDPOINT BEING USED ****/
        var path: String = "/api/v1/application/1111"
        /**** SET THE HTTP BODY BEING USED ****/
        var body : String = ""

        var headers = generateHeaders(identifier, secret, timestamp, method, contentType, path, body)

        print("Headers")
        print("\\nDate: " + headers.get("Date"))
        print("\\nContent-MD5: " + headers.get("Content-MD5"))
        print("\\nContent-Type: " + headers.get("Content-Type"))
        print("\\nAuthorization: " + headers.get("Authorization"))
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2 {...{
      "id": "c",
      "style": {
        "position": "relative"
      }
    }}>{`C#`}<a parentName="h2" {...{
        "href": "#c",
        "aria-label": "c permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <deckgo-highlight-code {...{
      "highlight-lines": "undefined"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`using RestSharp;
using System;
using System.Collections.Generic;
using System.Net.Http;
using System.Net.Http.Headers;
using System.Reflection;
using System.Text;
using System.Threading;
using System.Threading.Tasks;

namespace CredFinApiTest
{
    /// <summary>
    /// Both the .NET HttpClient and Restsharp are very strict in not allowing content headers to be added to GET requests.  To get around this we need to add a handler that can circumvent the 
    /// restrictions and add the headers we need for autentication/authorization.
    /// </summary>
    public class AddContentTypeHeaders : DelegatingHandler
    {
        private string _md5;

        public AddContentTypeHeaders(string md5)
        {
            _md5 = md5;
            InnerHandler = new HttpClientHandler();
        }

        /// <summary>
        /// Remove the headers we want to add from the invalid header list.
        /// </summary>
        private void RemoveInvalidHeadera(HttpRequestMessage request)
        {
            FieldInfo invalidHeadersField =
                typeof(HttpHeaders).GetField("invalidHeaders", BindingFlags.NonPublic | BindingFlags.Instance) ??   // System.Net.Http v2.2+
                typeof(HttpHeaders).GetField("_invalidHeaders", BindingFlags.NonPublic | BindingFlags.Instance)     // System.Net.Http before v2.2
            ;
            HashSet<string> invalidHeaders = (HashSet<string>)invalidHeadersField.GetValue(request.Headers);
            invalidHeaders.Remove("Content-Type");
            invalidHeaders.Remove("Content-MD5");
        }

        /// <summary>
        /// Add the content type headers required for authorisation/authentication
        /// </summary>
        private void AddContentHeaders(HttpRequestMessage request)
        {
            request.Headers.Add("Content-Type", "application/json");
            request.Headers.Add("Content-MD5", _md5);
        }

        protected override async Task<HttpResponseMessage> SendAsync(HttpRequestMessage request, CancellationToken token)
        {
            RemoveInvalidHeadera(request);
            AddContentHeaders(request);

            return await base.SendAsync(request, token); //Call next handler in pipeline
        }
    }

    internal class Program
    {
        public static void Main()
        {
            // Set your identifier and secrets
            string identifier = "xxxx";
            string secret = "xxxx";

            // Set your query path
            string path = "/api/v1/analyses/44834/report/pdf";

            //Make the call to get the data
            var response = Program.GetMethod(identifier, secret, path);

            //Write the response to a file
            System.IO.File.WriteAllBytes(@"d:\\temp\\credfin.pdf", response);
        }

        private static byte[] GetMethod(string identifier, string secret, string path)
        {
            string timestamp = DateTime.UtcNow.ToString("ddd, dd MMM yyyy HH:mm:ss") + " GMT";
            string method = "GET";
            string contentType = "application/json";
            string body = "";

            byte[] md5 = Program.Md5(body);
            string contentMd5 = Program.Base64(md5);
            string[] messagePartsList = { method, contentMd5, contentType, timestamp, path };
            string messageParts = string.Join("\\n", messagePartsList);
            byte[] hmacMessage = Program.Hmac(secret, messageParts);
            string hmacBase64 = Program.Base64(hmacMessage);
            string authorization = identifier + ":" + hmacBase64;

            string baseUrl = "https://banks-staging.talefin.com";
            string remoteUrl = baseUrl + path;

            //Add the handler to the client, so the correct headers will be added when making the request.
            var options = new RestClientOptions(baseUrl)
            {
                ConfigureMessageHandler = handler =>
                    new AddContentTypeHeaders(contentMd5)
            };
            var client = new RestClient(options);

            //Create the request.  The Content headers will be added by the handler.  
            var request = new RestRequest(path, RestSharp.Method.Get);
            request.AddHeader("Date", timestamp);
            request.AddHeader("Authorization", "HMAC " + authorization);

            //Now download the report
            return client.DownloadData(request);
        }

        private static byte[] Hmac(string secret, string message)
        {
            ASCIIEncoding encoding = new ASCIIEncoding();
            byte[] keyBytes = encoding.GetBytes(secret);
            byte[] messageBytes = encoding.GetBytes(message);
            System.Security.Cryptography.HMACSHA256 cryptographer = new System.Security.Cryptography.HMACSHA256(keyBytes);

            return cryptographer.ComputeHash(messageBytes);
        }

        private static string Base64(byte[] bytes)
        {
            return System.Convert.ToBase64String(bytes);
        }

        private static byte[] Md5(string strInput)
        {
            System.Security.Cryptography.MD5 md5 = System.Security.Cryptography.MD5.Create();
            byte[] inputBytes = System.Text.Encoding.ASCII.GetBytes(strInput);
            return md5.ComputeHash(inputBytes);
        }
    }
}`}</code>{`
        `}</deckgo-highlight-code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      